import OpacityParallax from "@components/parallax-opacity"
import ParallaxSticky from "@components/parallax-sticky"
import ScrollPinReveal, { RevealBlock } from "@components/scroll-pin-reveal"
import styled from "@emotion/styled"
import {
  Block,
  CenterCite,
  CenterText,
  Figure,
  Intro,
  IntroImage,
  NavLink,
  NavLinks,
  ParallaxBlock,
  StoryHeading,
  StoryHeadingBg,
  StoryMeta,
  StoryTitle,
  TextBlock,
  VideoQuote,
} from "@components/story-components"
import StoryPicker from "@components/storypicker"
import Video from "@components/video"
import YoutubeVideo from "@components/YoutubeVideo"
import { graphql } from "gatsby"
import React from "react"
import { FluidImg } from "src/types"

import odinMp4 from "../../assets/odin.mp4"
import odinWebM from "../../assets/odin.webm"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LeftIcon from "../../images/chevron-left.inline.svg"
import RightIcon from "../../images/chevron-right.inline.svg"
import GatsbyImage from "gatsby-image"

type Props = {
  data: {
    odin: FluidImg
    family: FluidImg
    lanaoDelSur: FluidImg
    marawi: FluidImg
    marawi2: FluidImg
    mosque: FluidImg
  }
}

const IndexPage = ({ data }: Props) => {
  return (
    <Layout>
      <SEO title="Odin's story" />
      <Block>
        <Video
          sources={[
            { src: odinWebM, type: "video/webm" },
            { src: odinMp4, type: "video/mp4" },
          ]}
        />
        <VideoQuote>
          <CenterCite maxWidth="63rem" color="wheat">
            Odin's Story
            <StoryMeta>Philippines</StoryMeta>
          </CenterCite>
        </VideoQuote>

        <StoryHeading>
          <StoryHeadingBg>
            <NavLinks>
              <NavLink to="/story/amir">
                <LeftIcon height="1em" strokeWidth="2px" /> Previous
              </NavLink>
              <NavLink to="/story/rahma">
                Next story <RightIcon height="1em" strokeWidth="2px" />
              </NavLink>
            </NavLinks>
            <StoryTitle>
              ‘When ISIS came here, they approached me, but I didn’t give them
              the assurances that I will join them straight away’
            </StoryTitle>
          </StoryHeadingBg>
        </StoryHeading>
      </Block>
      <Intro>
        <IntroImage fluid={data.odin.childImageSharp.fluid} />
        <CenterText fontSize={["xl", "2xl"]}>
          ISIS said that if they got what they wanted, there would be peace. If
          they were given power, there would be peace. If they would lead us and
          control the government, there would be peace.
        </CenterText>
      </Intro>
      <OpacityParallax
        bg="cyprus"
        threshold={0.7}
        backgroundProps={{
          fluid: data.family.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat">
            <CenterText>
              We’ve grown up in a conflict area in Mindanao, and many of the
              people here are tired of war.
            </CenterText>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <TextBlock color="cyprus">
        <CenterText fontSize={["xl", "2xl"]}>
          I am a farmer in Lanao del Sur. When I was younger I only finished
          elementary schooling because my family couldn’t afford for me to keep
          attending and we had financial problems. Everyone knows that you can’t
          get a good job if you don’t finish school.
        </CenterText>
        <Figure maxWidth="50rem" mb="5xl">
          <GatsbyImage fluid={data.lanaoDelSur.childImageSharp.fluid} />
        </Figure>
      </TextBlock>
      <ScrollPinReveal bg="viridian">
        <RevealBlock color="wheat">
          <CenterText fontSize={["xl", "2xl"]}>
            In farming, sometimes we earn, and sometimes we don’t. When disaster
            strikes, our crops die and with it our livelihood. ISIS offered us
            money, and we joined them with the belief that our lives would
            become comfortable.
          </CenterText>
        </RevealBlock>
        <RevealBlock color="wheat">
          <CenterCite>‘We soon realized that we had joined a war’ </CenterCite>
        </RevealBlock>
      </ScrollPinReveal>

      <OpacityParallax
        backgroundProps={{
          fluid: data.marawi.childImageSharp.fluid,
        }}
      >
        <TextBlock p="0" height="100vh" width="100%" mt="120vh">
          <Image fluid={data.marawi2.childImageSharp.fluid} />
        </TextBlock>
      </OpacityParallax>
      <TextBlock color="cyprus">
        <CenterText fontSize={["xl", "2xl"]} p="0">
          We went to Marawi, and I was there during the climax of the siege.
          It’s sad to think of those buildings destroyed. It was never in our
          minds to destroy the city. During the shootings, anyone would be
          afraid. I thought, I won’t get anything from this, just death. I had
          my mother and family living in Marawi. My relatives were calling me
          and asking, why did you do this? Why did you join this kind of life?
          Our relatives told us to surrender, because they will be the ones to
          suffer. Parents, siblings, and relatives are really important.
        </CenterText>
      </TextBlock>
      <ParallaxBlock
        scaleFactor={1.2}
        backgroundProps={{
          fluid: data.mosque.childImageSharp.fluid,
          loading: "eager",
        }}
      >
        <TextBlock color="wheat">
          <CenterCite>‘After one month, I surrendered’</CenterCite>
        </TextBlock>
      </ParallaxBlock>

      <ScrollPinReveal>
        <RevealBlock color="cyprus">
          <CenterText fontSize={["xl", "2xl"]}>
            I was afraid once I left ISIS. We asked for amnesty, and initially
            the government made a lot of promises. We told them what we wanted –
            help and better economic opportunities - but until now, they haven’t
            fulfilled those wishes. How can we change our lives without capital?
          </CenterText>
        </RevealBlock>
        <RevealBlock color="cyprus">
          <CenterText>
            We are really poor. If we had money, we wouldn’t be asking for help.
            If you have money, you won’t do anything bad.
          </CenterText>
        </RevealBlock>
      </ScrollPinReveal>

      <TextBlock p="" py="5xl" bg="cyprus">
        <Figure>
          <YoutubeVideo videoId="WHaFHlm3Jz8" />
        </Figure>
      </TextBlock>
      <StoryPicker />
    </Layout>
  )
}

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

export const pageQuery = graphql`
  query {
    odin: file(relativePath: { eq: "odin.jpg" }) {
      ...BlockImage
    }
    family: file(relativePath: { eq: "odin/family.jpg" }) {
      ...BlockImage
    }
    lanaoDelSur: file(relativePath: { eq: "odin/lanao_del_sur.jpg" }) {
      ...BlockImage
    }
    marawi: file(relativePath: { eq: "odin/marawi.jpg" }) {
      ...BlockImage
    }
    marawi2: file(relativePath: { eq: "odin/marawi2.jpg" }) {
      ...BlockImage
    }
    mosque: file(relativePath: { eq: "odin/mosque.jpg" }) {
      ...BlockImage
    }
  }
`

export default IndexPage
